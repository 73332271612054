<template>
  <div>
    <section class="page-title text-center">
      <div class="container">
        <div class="content-box">
          <h1>{{ $t("Appointment") }} {{ $getByLang(name) }}</h1>
        </div>
      </div>
    </section>

    <!--SECTION START-->
    <section
      style="background-color: white; padding-top: 36px; padding-bottom: 150px"
    >
      <div class="container">
        <div class="row">
          <div class="col-md-8">
            <form method="post">
              <div class="mb-3">
                <label for="fname" class="form-label">
                  {{ $t("First Name") }}</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="fname"
                  v-model="body.fname"
                />
              </div>
              <div class="mb-3">
                <label for="lname" class="form-label">{{
                  $t("Last Name")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="lname"
                  v-model="body.lname"
                />
              </div>

              <div
                class="mb-3"
                v-if="body.services_id != 1 && body.services_id != 3"
              >
                <label for="address" class="form-label">
                  {{ $t("Address") }}</label
                >
                <input
                  type="text"
                  class="form-control"
                  id="address"
                  v-model="body.address"
                />
              </div>

              <div class="mb-3">
                <label for="personsNumber" class="form-label">
                  {{ $t("Service") }}
                </label>
                <b-form-select v-model="body.services_id" class="mb-3">
                  <b-form-select-option
                    v-for="item of servicesList"
                    :key="item.id"
                    :value="item.id"
                    >{{ $getByLang(item.name) }}</b-form-select-option
                  >
                </b-form-select>
              </div>
              <div class="mb-3" v-if="body.services_id">
                <label for="personsNumber" class="form-label">
                  {{ $t("Price Plan") }}
                  <span style="color: red">
                    {{ $t("per person") }}
                  </span>
                </label>
                <b-form-select v-model="body.prices_id" class="mb-3">
                  <b-form-select-option
                    v-for="item of pricesList"
                    :key="item.id"
                    :value="item.id"
                    >{{ item.minutes }} {{ $t("minutes") }} =
                    {{ item.price }}€</b-form-select-option
                  >
                </b-form-select>
              </div>
              <div class="mb-3">
                <label for="personsNumber" class="form-label">
                  {{ $t("No. of Persons") }}</label
                >
                <input
                  id="sb-wrap"
                  wrap
                  min="1"
                  type="number"
                  v-model="body.numberPersons"
                  :max="userList.length"
                  placeholder="--"
                  class="form-control"
                />
              </div>
              <div class="mb-3">
                <label for="phone" class="form-label">{{ $t("Phone") }}</label>

                <vue-tel-input
                  v-model="body.phone"
                  v-bind="{
                    mode: 'international',
                    defaultCountry: 'lu',
                  }"
                ></vue-tel-input>
              </div>
              <div class="mb-3">
                <label for="email" class="form-label">{{
                  $t("Email address")
                }}</label>
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  v-model="body.email"
                  placeholder="name@example.com"
                />
              </div>
              <div class="mb-3">
                <label for="validationTooltip04">{{ $t("Pay Type") }}</label>
                <select
                  class="custom-select"
                  id="validationTooltip04"
                  v-model="body.baytype"
                >
                  <option value="Cash">{{ $t("Cash") }}</option>

                  <option value="Online">{{ $t("Online") }}</option>
                  <option value="Package">{{ $t("Use Package") }}</option>

                  <option value="Gift">{{ $t("Use Gift") }}</option>
                </select>
              </div>

              <div class="mb-3" v-if="body.baytype == 'Gift'">
                <label for="gift_id" class="form-label">{{
                  $t("Gift Card Number")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="gift_id"
                  v-model="body.gift_id"
                />
              </div>

              <div class="mb-3" v-if="body.baytype == 'Package'">
                <label for="gift_id" class="form-label">{{
                  $t("Sessions Number")
                }}</label>
                <input
                  type="text"
                  class="form-control"
                  id="package_id"
                  v-model="body.package_id"
                />
              </div>
              <div class="mb-3">
                <label for="note" class="form-label">{{ $t("note") }}</label>
                <textarea
                  class="form-control"
                  v-model="body.note"
                  id="note"
                  rows="3"
                ></textarea>
              </div>
            </form>
          </div>
          <div class="col-md-4">
            <b-calendar
              v-model="value"
              start-weekday="1"
              block
              :min="minDate"
            ></b-calendar>
            <div style="margin-top: 15px; overflow-y: scroll; height: 422px">
              <b-list-group v-if="!off && value">
                <b-list-group-item
                  v-for="item of timeList"
                  :key="item.time"
                  class="flex-column align-items-start"
                >
                  <div class="d-flex w-100 justify-content-between">
                    <h5 class="mb-1">{{ item.time }}</h5>
                  </div>

                  <p class="mb-1">
                    <b-form-group v-slot="{ ariaDescribedby }">
                      <b-form-checkbox
                        v-for="option in userList"
                        v-model="selected"
                        :disabled="retObjVal(option, item)"
                        :key="option.id"
                        :value="{
                          time: item.time,
                          id: option.id,
                          key: item.key,
                        }"
                        :aria-describedby="ariaDescribedby"
                        :name="'flavour-' + item.time"
                      >
                        <img
                          style="height: 50px; width: 50px; padding-bottom: 5px"
                          :src="baseUploadURL + option.image"
                          alt=""
                        />
                        {{ option.name }}
                        {{
                          option.recommended == 1
                            ? "(" + $t("recommended") + ")"
                            : ""
                        }}
                      </b-form-checkbox>
                    </b-form-group>
                  </p>
                </b-list-group-item>
              </b-list-group>
              <!-- <img
                v-else-if="off && value"
                src="../assets/123.jpg"
                style="width: 79%;height: 88%;opacity: .1;"
                alt=""
              />
              <img
                v-else
                src="../assets/img_370683.png"
                style="width: 79%;height: 88%;opacity: .1;"
                alt=""
              /> -->
            </div>
          </div>
        </div>
        <b-button
          block
          variant="danger"
          :disabled="
            !this.body.fname &&
            !this.body.lname &&
            !this.body.phone &&
            !this.body.email &&
            !this.selected &&
            !this.value
          "
          @click="showAdd"
          style="background-color: #f26838; color: white"
          >{{ $t("Confirm") }}</b-button
        >
      </div>
    </section>

    <b-modal
      id="bv-modal-example"
      size="xl"
      style="z-index: 10650009999"
      hide-footer
    >
      <template #modal-title>
        {{ $t("Confirm") }}
      </template>
      <div class="d-block text-center">
        <div class="row">
          <div class="col-md-8">
            <form method="post" class="row">
              <div class="col-md-4 x">
                <div class="mb-3">
                  <label for="fname" class="form-label">
                    {{ $t("First Name") }}</label
                  >
                  {{ body.fname }}
                </div>
              </div>
              <div class="col-md-4 x">
                <div class="mb-3">
                  <label for="lname" class="form-label">{{
                    $t("Last Name")
                  }}</label>
                  {{ body.lname }}
                </div>
              </div>
              <div
                class="col-md-4 x"
                v-if="body.services_id != 1 && body.services_id != 3"
              >
                <div class="mb-3">
                  <label for="address" class="form-label">
                    {{ $t("Address") }}</label
                  >
                  {{ body.address }}
                </div>
              </div>
              <div class="col-md-4 x">
                <div
                  class="mb-3"
                  v-if="servicesList && servicesList.length > 0"
                >
                  <label for="personsNumber" class="form-label">
                    {{ $t("Service") }}
                  </label>
                  {{
                    $getByLang(
                      servicesList.find((el) => el.id == body.services_id).name
                    )
                  }}
                </div>
              </div>
              <div class="col-md-4 x" v-if="body.services_id">
                <div class="mb-3" v-if="pricesList && pricesList.length > 0">
                  <label for="personsNumber" class="form-label">
                    {{ $t("Price Plan") }}
                    <span style="color: red">
                      {{ $t("per person") }}
                    </span>
                  </label>
                  {{ pricesList.find((el) => el.id == body.prices_id).minutes }}
                  {{ $t("minutes") }} =
                  {{ pricesList.find((el) => el.id == body.prices_id).price }}€
                </div>
              </div>
              <div class="col-md-4 x">
                <div class="mb-3">
                  <label for="personsNumber" class="form-label">
                    {{ $t("No. of Persons") }}</label
                  >
                  {{ body.numberPersons }}
                </div>
              </div>
              <div class="col-md-4 x">
                <div class="mb-3">
                  <label for="phone" class="form-label">{{
                    $t("Phone")
                  }}</label>

                  {{ body.phone }}
                </div>
              </div>
              <div class="col-md-4 x">
                <div class="mb-3">
                  <label for="email" class="form-label">{{
                    $t("Email address")
                  }}</label>
                  {{ body.email }}
                </div>
              </div>
              <div class="col-md-4 x">
                <div class="mb-3">
                  <label for="validationTooltip04">{{ $t("Pay Type") }}</label>
                  {{
                    body.baytype == "Package" || body.baytype == "Gift"
                      ? $t("Use " + body.baytype)
                      : $t(body.baytype)
                  }}
                </div>
              </div>

              <div class="col-md-4 x" v-if="body.baytype == 'Gift'">
                <div class="mb-3" v-if="body.baytype == 'Gift'">
                  <label for="gift_id" class="form-label">{{
                    $t("Gift Card Number")
                  }}</label>
                  {{ body.gift_id }}
                </div>
              </div>

              <div class="col-md-4 x" v-if="body.baytype == 'Package'">
                <div class="mb-3">
                  <label for="gift_id" class="form-label">{{
                    $t("Sessions Number")
                  }}</label>
                  {{ body.package_id }}
                </div>
              </div>
              <div class="col-md-4 x">
                <div class="mb-3">
                  <label for="note" class="form-label">{{ $t("note") }}</label>
                  {{ body.note }}
                </div>
              </div>
            </form>
          </div>
          <div class="col-md-4">
            {{ value }}

            <br />
            <!-- {{ selected }} -->
            <!-- userList -->
            <p class="mb-1" v-for="item of selected" :key="item.id">
              <img
                style="height: 50px; width: 50px; padding-bottom: 5px"
                :src="
                  baseUploadURL + userList.find((el) => el.id == item.id).image
                "
                alt=""
              />
              {{ userList.find((el) => el.id == item.id).name }}
              <span style="color: red"> {{ item.time }}</span>
            </p>
          </div>
        </div>
      </div>

      <b-button
        block
        variant="danger"
        :disabled="
          !this.body.fname &&
          !this.body.lname &&
          !this.body.phone &&
          !this.body.email &&
          !this.selected &&
          !this.value
        "
        @click="addAction"
        style="background-color: #f26838; color: white"
        >{{ $t("Confirm") }}</b-button
      >
      <b-button class="mt-3" block @click="$bvModal.hide('bv-modal-example')"
        >Close</b-button
      >
    </b-modal>

    <div>
      <stripe-checkout
        ref="checkoutRef"
        mode="payment"
        :pk="publishableKey"
        :line-items="lineItems"
        :success-url="successURL"
        :cancel-url="cancelURL"
        @loading="(v) => (loading = v)"
      />
    </div>
  </div>
</template>

<script>
import { StripeCheckout } from "@vue-stripe/vue-stripe";
export default {
  components: {
    StripeCheckout,
  },
  data() {
    this.publishableKey =
      "pk_live_51HQAAeKJnzPR2F8FX59aaFrWGwpNLgd48uKrvRLB6F5FUYong46ShbrDaGbm2Qt1JrUazDb2XYEVLczJdiNOM0hF000flxqUoI";
    const now = new Date();
    const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
    // 15th two months prior
    const minDate = new Date(today);
    return {
      baseUploadURL: this.$baseUploadURL2,
      minDate,
      name: null,
      id: null,
      value: null,
      selected: [],
      dayWork: null,
      off: false,
      timeList: [],
      userList: [],
      servicesList: [],
      pricesList: [],
      allPricesList: [],
      body: {
        fname: null,
        lname: null,
        phone: null,
        email: null,
        note: null,
        baytype: "Cash",
        services_id: 1,
        prices_id: null,
        massage_id: null,
        gift_id: null,
        package_id: null,
        massageType: "booking",
        address: null,
        numberPersons: 1,
      },
      orderId: null,
      loading: false,
      lineItems: [
        {
          price: null, // The id of the one-time price you created in your Stripe dashboard
          quantity: 1,
        },
      ],
      successURL: null,
      cancelURL: null,

      twoValues: false,
    };
  },

  created() {
    window.location.href =
      "https://salonkee.lu/salon/egypta-massage-bereldange?lang=fr";

    const slug = this.$route.params.slug;
    // console.log(this.$route);
    if (this.$route.query.id) {
      // alert(this.$route.query.id);
      if (this.$route.query.success) {
        // alert(1);
        // orderId;

        this.$http2
          .post(`massageorder/update/${this.orderId}`, {
            bayDone: 1,
          })
          .then((res) => {
            this.$swal.fire({
              icon: "success",
              title: "Success",
              text: this.$t("Success Done"),
              showConfirmButton: false,
              timer: 1500,
            });
          });
      } else {
        this.$swal.fire({
          icon: "error",
          title: "Error",
          text: "error",
          showConfirmButton: false,
          timer: 1500,
        });
        // alert(0);
      }
    }
    if (slug) {
      this.$http2.get(`massage/getById/${slug}`).then(
        (res) => {
          if (res.data) {
            this.name = res.data.name;
            this.id = res.data.id;

            this.servicesList = res.data.services.filter(
              (itm) => itm.id == 1 || itm.id == 3
            );
            this.allPricesList = res.data.prices;

            this.pricesList = [];
            for (const item of this.allPricesList) {
              if (item.services.find((el) => el.id == 1)) {
                this.pricesList.push(item);
              }
            }
            this.body.prices_id = this.pricesList[0].id;
          }
        },
        (err) => {
          console.log(err);
        }
      );
      this.$http2.get(`daywork`).then(
        (res) => {
          if (res.data) {
            this.dayWork = res.data[0];
          }
        },
        (err) => {
          console.log(err);
        }
      );
      this.$http2.get(`users`).then(
        (res) => {
          if (res.data) {
            this.userList = res.data;
          }
        },
        (err) => {
          console.log(err);
        }
      );
    }
  },
  methods: {
    submit() {
      // You will be redirected to Stripe's secure checkout page
      this.$refs.checkoutRef.redirectToCheckout();
    },

    retObjVal(user, item) {
      let lock = false;

      if (
        user.massageorder.find(
          (el) =>
            el.time == item.key &&
            new Date(el.date).getTime() == new Date(this.value).getTime()
        ) != null
      ) {
        lock = true;
      }

      const days = [
        "Sunday",
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
      ];
      const d = new Date(this.value);
      const dayName = days[d.getDay()];

      if (user[dayName] == 1) {
        lock = true;
      }
      if (user.hold && user.holdFrom) {
        var d1 = new Date(this.value);
        var d2 = new Date(user.hold);
        var d3 = new Date(user.holdFrom);
        if (d1.getTime() <= d2.getTime() && d1.getTime() >= d3.getTime()) {
          lock = true;
        }
      }

      return lock;
    },
    addAction() {
      if (this.body.baytype == "Online" || this.body.baytype == "Cash") {
        this.add();
      }
      if (this.body.baytype == "Package") {
        if (this.body.package_id != null) {
          this.add();
        } else {
          this.$swal.fire({
            icon: "error",
            title: "Error",
            text: this.$t("Field Package must be filled out"),
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
      if (this.body.baytype == "Gift") {
        if (this.body.gift_id != null) {
          this.add();
        } else {
          this.$swal.fire({
            icon: "error",
            title: "Error",
            text: this.$t("Field Gift must be filled out"),
            showConfirmButton: false,
            timer: 1500,
          });
        }
      }
    },
    showAdd() {
      if (
        this.body.fname &&
        this.body.lname &&
        this.body.phone &&
        this.body.email &&
        this.body.prices_id &&
        this.selected &&
        this.selected[0] &&
        this.selected[0].key &&
        this.value &&
        this.body.numberPersons
      ) {
        this.$bvModal.show("bv-modal-example");
      } else {
        this.$swal.fire({
          icon: "error",
          title: "Error",
          text: this.$t("Fields must be filled out"),
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    add() {
      if (
        this.body.fname &&
        this.body.lname &&
        this.body.phone &&
        this.body.email &&
        this.body.prices_id &&
        this.selected &&
        this.selected[0] &&
        this.selected[0].key &&
        this.value &&
        this.body.numberPersons
      ) {
        this.lineItems[0].price = this.pricesList.find(
          (el) => el.id == this.body.prices_id
        ).api_id;
        this.lineItems[0].quantity = Number(this.body.numberPersons);
        this.$eventHub.$emit("showSpinner", true);
        this.$http2
          .post(`massageorder/add`, {
            ...this.body,
            time: this.selected.map((el) => el.key),
            date: this.value,
            user_id: this.selected.map((el) => el.id),
            massage_id: this.id,
            bayDone: this.body.baytype == "Online" ? 0 : 1,
          })
          .then(
            (res) => {
              this.successURL = `https://egyptamassage.com${this.$getLink()}bayDone/massageorder/${
                res.data
              }/success`;
              this.cancelURL = `https://egyptamassage.com${this.$getLink()}bayDone/massageorder/${
                res.data
              }/error`;
              this.orderId = res.data;

              setTimeout(() => {
                if (this.body.baytype == "Online") {
                  this.submit();
                } else {
                  this.$swal.fire({
                    icon: "success",
                    title: "Success",
                    text:
                      !this.body.baytype == "Online"
                        ? this.$t("toBay")
                        : this.$t("Success Done"),
                    showConfirmButton: false,
                    timer: 1500,
                  });
                  this.$eventHub.$emit("showSpinner", false);
                  this.$router.push(this.$getLink());
                }
              }, 1500);
            },
            (err) => {
              this.$eventHub.$emit("showSpinner", false);
              this.$swal.fire({
                icon: "error",
                title: "Error",
                text: this.$t("Something has gone wrong"),
                showConfirmButton: false,
                timer: 1500,
              });
            }
          );

        if (!this.body.baytype == "Online") {
          this.$eventHub.$emit("showSpinner", false);

          this.$swal.fire({
            icon: "success",
            title: "Success",
            text:
              !this.body.baytype == "Online"
                ? this.$t("toBay")
                : this.$t("Success Done"),
            showConfirmButton: false,
            timer: 1500,
          });
        }
        setTimeout(() => {
          if (!this.body.baytype == "Online") {
            this.$bvModal.hide("bv-modal-example");
            this.$router.push(this.$getLink());
          }
        }, 1500);
      } else {
        this.$swal.fire({
          icon: "error",
          title: "Error",
          text: this.$t("Fields must be filled out"),
          showConfirmButton: false,
          timer: 1500,
        });
      }
    },
    getDayList(dateString) {
      if (dateString) {
        const days = [
          "Sunday",
          "Monday",
          "Tuesday",
          "Wednesday",
          "Thursday",
          "Friday",
          "Saturday",
        ];
        const d = new Date(dateString);
        const dayName = days[d.getDay()];
        const option = { start: null, num: null, off: false };
        for (const key in this.dayWork) {
          if (key.startsWith(dayName) && key.endsWith("Start")) {
            option.start = this.dayWork[key];
          } else if (key.startsWith(dayName) && key.endsWith("Number")) {
            option.num = this.dayWork[key];
          } else if (key.startsWith(dayName) && key.endsWith("Hold")) {
            option.off = this.dayWork[key];
          }
        }
        const list = [Number(option.start)];
        // let timeList = [];
        for (let i = 1; i < option.num; i++) {
          list.push(list[list.length - 1] + 2);
        }
        // for (let i = 0; i < 24; i++) {
        //   const xtime =
        //     String(i % 12 ? i % 12 : 12) + ':00 ' + (i >= 12 ? 'pm' : 'am');
        //   let time = xtime;
        //   if (xtime == '8:00 am') {
        //     time = '9:00 am';
        //   }

        //   // if (xtime == '8:00 pm') {
        //   //   time = '7:00 pm';
        //   // }
        //   timeList.push({
        //     key: i,
        //     time: time,
        //   });
        // }

        const timeList = [
          { key: 0, time: "12:00 am" },
          { key: 1, time: "1:00 am" },
          { key: 2, time: "2:00 am" },
          { key: 3, time: "3:00 am" },
          { key: 4, time: "4:00 am" },
          { key: 5, time: "5:00 am" },
          { key: 6, time: "6:00 am" },
          { key: 7, time: "7:00 am" },
          { key: 8, time: "9:00 am" },
          { key: 9, time: "9:00 am" },
          { key: 10, time: "10:00 am" },
          { key: 11, time: "11:00 am" },
          { key: 12, time: "12:00 pm" },
          { key: 13, time: "1:00 pm" },
          { key: 14, time: "2:00 pm" },
          { key: 15, time: "3:00 pm" },
          { key: 16, time: "4:00 pm" },
          { key: 17, time: "5:00 pm" },
          { key: 18, time: "6:00 pm" },
          { key: 19, time: "7:00 pm" },
          { key: 20, time: "8:00 pm" },
          { key: 21, time: "9:00 pm" },
          { key: 22, time: "10:00 pm" },
          { key: 23, time: "11:00 pm" },
          { key: 24, time: "9:30 am" },
          { key: 25, time: "12:30 pm" },
          { key: 26, time: "3:30 pm" },
          { key: 27, time: "6:30 pm" },
        ];

        //
        // console.log(JSON.stringify(timeList));
        // 9:30
        // 11:00
        // 12:30
        // 2:00
        // 3:30
        // 5:00
        // 6:30
        // console.log(list);
        this.timeList = [
          { key: 24, time: "9:30 am" },
          { key: 11, time: "11:00 am" },
          { key: 25, time: "12:30 pm" },
          { key: 14, time: "2:00 pm" },
          { key: 26, time: "3:30 pm" },
          { key: 17, time: "5:00 pm" },
          { key: 27, time: "6:30 pm" },
        ];
        // for (const item of list) {
        //   this.timeList.push({
        //     key: timeList.find((el) => el.key == item).key,
        //     time: timeList.find((el) => el.key == item).time,
        //   });
        // }
      }
    },
  },
  watch: {
    value(dateString) {
      this.getDayList(dateString);
      this.selected = [];
    },
    selected(newVal, old) {
      if (old.length != 0) {
        if (this.twoValues == true) {
          let c = 0;
          if (newVal.length > this.body.numberPersons * 2) {
            this.selected = [newVal[newVal.length - 1]];
          }
        } else {
          let reAdd = false;
          for (const item of newVal) {
            if (item.time != old[0].time) {
              reAdd = true;
            }
          }
          if (reAdd) {
            this.selected = [newVal[newVal.length - 1]];
          }
        }
      }

      if (this.twoValues == true && newVal.length == 1) {
        const item1 = newVal[0];
        const index = this.timeList.findIndex((el) => el.key == item1.key);

        if (this.timeList.length == index + 1) {
          // Error You don't can use thistime
          this.$swal.fire({
            icon: "error",
            title: "Error",
            text: this.$t(
              "Error You don't can use thistime Next time Not Available"
            ),
            showConfirmButton: false,
            timer: 1500,
          });
          setTimeout(() => {
            this.selected = [];
          }, 1000);
          console.log(this.selected);
        } else {
          const item2 = this.timeList[index + 1];
          const user = this.userList.find((el) => el.id == item1.id);
          if (this.retObjVal(user, item2) == true) {
            this.$swal.fire({
              icon: "error",
              title: "Error",
              text: this.$t(
                "Error You don't can use thistime Next time Not Available"
              ),
              showConfirmButton: false,
              timer: 1500,
            });
            setTimeout(() => {
              this.selected = [];
            }, 1000);
          } else {
            this.selected.push({
              id: item1.id,
              key: item2.key,
              time: item2.time,
            });
          }
        }
      }
      if (this.twoValues == true && newVal.length == 3) {
        const item1 = newVal[2];
        const index = this.timeList.findIndex((el) => el.key == item1.key);

        if (this.timeList.length == index + 1) {
          // Error You don't can use thistime
          this.$swal.fire({
            icon: "error",
            title: "Error",
            text: this.$t(
              "Error You don't can use thistime Next time Not Available"
            ),
            showConfirmButton: false,
            timer: 1500,
          });
          setTimeout(() => {
            this.selected.pop();
          }, 1000);
        } else {
          const item2 = this.timeList[index + 1];
          const user = this.userList.find((el) => el.id == item1.id);
          if (this.retObjVal(user, item2) == true) {
            this.$swal.fire({
              icon: "error",
              title: "Error",
              text: this.$t(
                "Error You don't can use thistime Next time Not Available"
              ),
              showConfirmButton: false,
              timer: 1500,
            });
            setTimeout(() => {
              this.selected = [];
            }, 1000);
          } else {
            this.selected.push({
              id: item1.id,
              key: item2.key,
              time: item2.time,
            });
          }
        }
      }

      if (this.twoValues == true && newVal.length == 5) {
        const item1 = newVal[4];
        const index = this.timeList.findIndex((el) => el.key == item1.key);

        if (this.timeList.length == index + 1) {
          // Error You don't can use thistime
          this.$swal.fire({
            icon: "error",
            title: "Error",
            text: this.$t(
              "Error You don't can use thistime Next time Not Available"
            ),
            showConfirmButton: false,
            timer: 1500,
          });
          setTimeout(() => {
            this.selected.pop();
          }, 1000);
        } else {
          const item2 = this.timeList[index + 1];
          const user = this.userList.find((el) => el.id == item1.id);
          if (this.retObjVal(user, item2) == true) {
            this.$swal.fire({
              icon: "error",
              title: "Error",
              text: this.$t(
                "Error You don't can use thistime Next time Not Available"
              ),
              showConfirmButton: false,
              timer: 1500,
            });
            setTimeout(() => {
              this.selected = [];
            }, 1000);
          } else {
            this.selected.push({
              id: item1.id,
              key: item2.key,
              time: item2.time,
            });
          }
        }
      }
    },
    "body.services_id"(val) {
      this.pricesList = [];
      for (const item of this.allPricesList) {
        if (item.services.find((el) => el.id == val)) {
          this.pricesList.push(item);
        }
      }

      this.body.prices_id = this.pricesList[0].id;
    },
    "body.prices_id"(val) {
      if (val) {
        const one = this.pricesList.find((item) => item.id == val);
        if (one.minutes > 90) {
          this.twoValues = true;
        } else {
          this.twoValues = false;
        }
        this.selected = [];
      }
    },
  },
};
</script>

<style>
.x label {
  display: block;
}
</style>
